<template>
  <div>
    <b-row class="justify-content-center">
      <b-col cols="6">
        <b-card>
          <b-row
            v-if="support_data.support_status=='completed'"
            class="mt-25"
          >
            <b-col cols="12">
              <b-alert
                variant="secondary"
                show
              >
                <div class="alert-body">
                  <span>{{ $t('support_detail.closed_support_warning_title') }}</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <h6 class="text-primary text-left">
            {{ $t('support_detail.sub_title') }}
          </h6>
          <h4 class="text-warning">
            ## {{ support_data.subject }}
          </h4>
          <b-row class="justify-content-between mx-50">
            <div>
              <span>{{$t("support_detail.support_status")}}</span><br>
              <b-badge
                  v-if="support_data.support_status=='new'"
                  variant="info"
              >
                {{ $t("support_detail.support_status_new") }}
              </b-badge>
              <b-badge
                  v-if="support_data.support_status=='inprocessing'"
                  variant="warning"
              >
                {{ $t("support_detail.support_status_inprocessing") }}
              </b-badge>
              <b-badge
                  v-if="support_data.support_status=='completed'"
                  variant="success"
              >
                {{ $t("support_detail.support_status_completed") }}
              </b-badge>
              <b-badge
                  v-if="support_data.support_status=='cancelled'"
                  variant="danger"
              >
                {{ $t("support_detail.support_status_cancelled") }}
              </b-badge>
              <b-badge
                  v-if="support_data.support_status=='customer_replied'"
                  variant="primary"
              >
                {{ $t("support_detail.support_status_customer_replied") }}
              </b-badge>
              <b-badge
                  v-if="support_data.support_status=='answered'"
                  variant="secondary"
              >
                {{ $t("support_detail.support_status_answered") }}
              </b-badge>
            </div>
            <div>
              <span>{{$t('support_detail.created')}}</span><br>
              <span>{{ support_data.created }}</span>
            </div>
            <div>
              <span>{{$t('support_detail.last_update')}}</span><br>
              <span>{{ support_data.modified }}</span>
            </div>
          </b-row>
          <hr>
          <!-- form -->
          <validation-observer
            v-show="answer_tab_status"
            ref="surveyAnswerForm"
            #default="{invalid}"
          >
            <b-form @submit.prevent="btn_create_support">
              <b-row class="mt-25">
                <b-col cols="12">
                  <b-form-group>
                    <label for="comment"><span class="text-danger text-bold">*</span> {{
                      $t('new_support.comment') }}</label>
                    <validation-provider
                      #default="{ errors }"
                      name="comment"
                      rules="required"
                    >
                      <quill-editor
                        id="message"
                        v-model="comment"
                        :state="errors.length > 0 ? false: comment != null ? true : null"
                        :options="editorOptions"
                      />
                      <small
                        v-if="errors[0]"
                        class="text-danger"
                      >{{ $t('core.required') }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col cols="12">
                  <b-alert
                    variant="warning"
                    show
                  >
                    <div class="alert-body">
                      <span>{{ $t('new_support.alert_info_message') }}</span>
                    </div>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col cols="12">
                  <!-- basic -->
                  <b-form-group
                    :label="$t('new_support.additional')"
                    label-for="attacment"
                  >
                    <b-form-file
                      v-model="additional_files"
                      :placeholder="$t('new_support.additional_files_placeholder')"
                      multiple
                    />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row class="mt-25">
                <b-col class="d-flex justify-content-between">
                  <b-button
                    size="sm"
                    variant="primary"
                    type="submit"
                    :disabled="invalid"
                  >
                    <span class="text-nowrap">{{ $t('new_support.btn_send') }}</span>
                  </b-button>
                  <b-button
                      size="sm"
                      variant="danger"
                      type="submit"
                      @click="btn_compiled_support"
                  >
                <span class="text-nowrap"><feather-icon
                    class="mr-25"
                    icon="CheckCircleIcon"
                />{{ $t('support_detail.btn_complite_support') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-row v-if="support_data.support_status!='completed'">
            <b-col class="d-flex justify-content-between">
              <b-button
                v-show="answer_tab_status==false"
                size="sm"
                variant="primary"
                type="submit"
                @click="answer_tab_status=true"
              >
                <span class="text-nowrap"><feather-icon
                  class="mr-25"
                  icon="MessageCircleIcon"
                />{{ $t('support_detail.btn_send_answer') }}</span>
              </b-button>
              <b-button
                v-show="answer_tab_status==false"
                size="sm"
                variant="danger"
                type="submit"
                @click="btn_compiled_support"
              >
                <span class="text-nowrap"><feather-icon
                  class="mr-25"
                  icon="CheckCircleIcon"
                />{{ $t('support_detail.btn_complite_support') }}</span>
              </b-button>
            </b-col>
          </b-row>
          <hr>
          <!--          Comments-->
          <b-row
            v-for="(comment, index) in comments_list"
            :id="`bookmark-${index}`"
            :key="index"
          >
            <b-col cols="12">
              <b-alert
                :variant="comment.comment_user.id==support_data.request_user.id ? 'info': 'secondary'"
                show
              >
                <div class="alert-heading">
                  <div class="d-flex justify-content-between">
                    <div>

                      <i
                        v-if="comment.comment_user.id==support_data.request_user.id"
                        class=" border p-50 bg-white pi pi-user mr-50"
                      />
                      <i
                        v-else
                        class=" border p-50 bg-white pi pi-building-columns mr-50"
                      />
                      {{ comment.comment_user.name }}
                    </div>
                    <div>{{ comment.created }}</div>
                  </div>
                </div>
                <div class="alert-body">
                  <div v-html="comment.comment" />
                </div>
                <div class="alert-footer">
                  <hr>
                  <div
                    v-for="(media, index) in comment_medias"
                    v-if="media.id==comment.id"
                    :id="`media-${index}`"
                    :key="index"
                  >
                    <b-link
                      v-for="(file, index) in media.files"
                      :id="`file-${index}`"
                      :key="index"
                      target="_blank"
                      :href="file.file"
                    >
                      <b-badge
                        class="mr-25"
                        variant="light-primary"
                      >
                        <feather-icon
                          icon="FileIcon"
                          class="mr-25"
                        />
                        <span>{{ file.file_name }}</span>
                      </b-badge>
                    </b-link>
                  </div>
                </div>
              </b-alert>
            </b-col>
          </b-row>
          <!--          Comments-->
        </b-card>
      </b-col>
      <b-col
        v-if="support_data.support_status=='completed'&&user_data.role=='client'"
        class="mt-1"
        cols="2"
      >
        <b-card no-body>
          <b-card-header class="p-50 bg-primary justify-content-center">
            <span class="text-white text-bold">{{$t('support_list.new_support_header')}}</span>
          </b-card-header>
          <span class="m-1">{{$t('support_list.new_support_body')}}</span>
          <b-card-footer class="text-center p-25 bg-warning">
            <b-button
              size="sm"
              variant="warning"
              @click="$router.push({ name: 'new_support'})"
            >
              <span class="text-nowrap">{{$t('support_list.btn_new_support')}} <feather-icon icon="ArrowRightCircleIcon" /></span>
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BAlert, BBadge,
  BButton,
  BCard, BCardFooter, BCardHeader,
  BCol, BForm, BFormFile, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend, BLink, BNavItem,
  BRow,
  VBModal,
  VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import router from '@/router'

export default {
  components: {
    BBadge,
    BLink,
    BNavItem,
    BCardHeader,
    BCardFooter,
    BForm,
    BAlert,
    BButton,
    BFormFile,
    quillEditor,
    BCard,
    BInputGroupPrepend,
    BInputGroup,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    'b-toggle': VBToggle,
    Ripple,
  },
  props: {},
  data() {
    return {
      answer_tab_status: false,
      temp_comment_id: null,
      support_data: {},
      comments_list: [],
      comment_medias: [],
      user_data: null,
      comment: null,
      subject: null,
      additional_files: null,
      editorOptions: {
        theme: 'snow', // veya 'bubble', istediğiniz temayı seçebilirsiniz
        placeholder: this.$t('new_support.comment'), // Placeholder'ı boş bırakabilirsiniz
      },
    }
  },
  watch: {},
  created() {
    this.user_data = getUserData()
    this.supportDetail()
  },
  mounted() {
  },
  methods: {
    btn_compiled_support() {
      const formData = new FormData()
      formData.append('support_status', "completed")
      useJwt.updateSupport(this.support_data.id, formData)
        .then(response => {
          location.reload()
        })
        .catch(error => {
          console.log(error)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('core.error'),
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
            },
          })
        })
    },
    btn_create_support() {
      this.$refs.surveyAnswerForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('comment', this.comment)
          formData.append('support', this.support_data.id)
          formData.append('comment_user', this.user_data.id)
          if (this.additional_files) {
            this.additional_files.forEach(file => {
              formData.append(file.name, file)
            })
          }
          useJwt.createSupportComments(formData)
            .then(response => {
              location.reload()
            })
            .catch(error => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: this.$t('core.error'),
                  icon: 'AlertCircleIcon',
                  variant: 'danger',
                  text: error.response.data.detail != undefined ? error.response.data.detail : error.response.data,
                },
              })
            })
        }
      })
    },
    supportDetail() {
      useJwt.supportDetail(router.currentRoute.params.id)
        .then(response => {
          this.support_data = response.data
          this.getComments()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getComments() {
      useJwt.getSupportCommentsList({
        support: router.currentRoute.params.id,
        perPage: 500,

      })
        .then(response => {
          this.comments_list = response.data.results
          this.comments_list.forEach(comment => {
            this.getCommentMedia(comment.id)
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getCommentMedia(comment_id) {
      this.temp_comment_id = comment_id
      useJwt.getSupportCommentMediaList({
        support: router.currentRoute.params.id,
        support_comment: comment_id,
        perPage: 500,
      })
        .then(response => {
          if (response.data.results.length > 0) {
            this.comment_medias.push({ id: response.data.results[0].support_comment, files: response.data.results })
          }
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    resetPage() {
      this.temp_comment_id = null
      this.support_data = {}
      this.comments_list = []
      this.comment_medias = []
      this.user_data = null
      this.comment = null
      this.subject = null
      this.additional_files = null
    },
  },
}
</script>
<style>
.ql-container {
  min-height: 200px; /* İstediğiniz minimum yüksekliği buraya girin */
}
</style>
